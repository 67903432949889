export default class Photo {
    // 2. Définition des valeurs par défaut des propriétés d'une photo.
    id: number;
    src: string;
    titre: string;
    description: string;
    prix: number;
    tag: Array<string>;

    constructor(
     id =0,
     src ="Lien",
     titre = "Titre",
     description = "Description",
     prix = 0,
     tag = []
    ) {
     // 3. Initialisation des propiétés d'une photo.
     this.id = id;
     this.src =src;
     this.titre =titre;
     this.description = description;
     this.prix = prix;
     this.tag = tag;
    }
   }