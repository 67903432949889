import { Box, Grid, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
// import ScrollyVideo from 'scrolly-video/dist/ScrollyVideo.cjs.jsx';
import React from 'react';

export default function HomeBanner() {

  const Image = require('../res/parallaxe-etoiles.jpg')


  const variant  = {
    hidden: { y: 15, opacity: 0 , color: "#4F4F4F",},
    visible1: { y: 0, opacity: 1, color: "#ffffff", 
      transition:{
        duration: 1,
        delay: 0.5,
        ease: [0, 0.30, 0.2, 1]
      } 
    },
    visible2: { y: 0, opacity: 1, color: "#ffffff", 
    transition:{
      duration: 2,
      delay: 0.5,
      ease: [0, 0.75, 0.2, 1]
    } 
  },
     
  }

  return (
    <Box >
      
      <Grid container direction="row" justifyContent="center" alignItems="center" className='no-banner' 
      sx={{ backgroundImage: `url(${Image})` , backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center',
      minHeight: "100vh" }}>
        <Grid item xs={10} >
          <motion.div
            className="box"
            variants={variant}
            initial= "hidden"
            animate="visible1"
          >
            <Typography variant='h1' fontFamily={'Poppins'} fontWeight={600} fontSize={70}>Sphertana,</Typography>
          </motion.div>
          <motion.div
            className="box"
            variants={variant}
            initial= "hidden"
            animate="visible2"
            style={{marginTop: '-15px'}}
          >
            <Typography variant='h1' fontFamily={'Poppins'} fontWeight={400} fontSize={70} >une autre sphère</Typography>
          </motion.div>
        </Grid>
      </Grid>
      <Grid container direction="column" sx={{ backgroundColor: "#333333",minHeight: '70vh',py:'15vh',px:'10vw'}}>
        <Grid item xs={5} container justifyContent='center' alignItems='center' mb='1rem' >
          <Typography fontFamily= {'Roboto'} fontWeight={600} sx={{ color:'#ffffff', fontSize: { xs:40, xl: 60 } }}>Propulsez-vous!</Typography>
        </Grid>
        <Grid item xs={7} container justifyContent='center' alignItems='center'>
          <Typography  fontFamily= {'Roboto'} fontWeight={400} sx={{ color:'#cccccc', fontSize: { sm: 18 } }}>
          Sphertana est une agence créative spécialisée dans la photographie, le développement web et le marketing digital. Nous sommes passionnés par la création de contenu visuel captivant pour impacter et aller dans le sens de nos clients qui sont notre priorité. 
          <br /><br />
          Avec notre expertise en photographie, nous sommes en mesure de capturer les moments clés de votre entreprise et de les présenter de manière professionnelle ou pour vos besoins en tant que particulier. Nous avons également une équipe de développeurs web qualifiés qui peuvent créer des sites web élégants et fonctionnels pour votre entreprise, avec des fonctionnalités telles que la réservation en ligne, le panier d'achat et bien plus encore. 
          <br /><br />
          Enfin, notre équipe de marketing digital peut aider à promouvoir votre entreprise en ligne grâce à une stratégie personnalisée, comprenant la publicité en ligne, les médias sociaux et le référencement. Contactez-nous dès aujourd'hui pour discuter de la façon dont nous pouvons vous aider à atteindre vos objectifs commerciaux avec notre créativité et notre expertise.
          </Typography>
        </Grid>
      </Grid>  
  
    </Box>


  );
}