import React from "react";
import { Grid, Box, Typography, styled } from "@mui/material";
import { ItemW } from "../pages/WorkPage";

interface Props {
    item: ItemW;
}

function ItemWork(props: Props) {

    return ( 
        <Grid container  direction={{xs:'column', sm: props.item.id % 2 == 0 ? "row" : "row-reverse"}} justifyContent='flex-end'  className='wrapper' sx={{ height:'88Vh',width:'100%'  }}>
            <Grid item container direction='row' justifyContent='center' xs={6} sx={{ height:'100%',width:'100%'}}>
                <img src={props.item.ima} alt="" className="fluid" />
            </Grid>
            <Grid item container direction='column' alignItems='center' justifyContent='center'  xs={6}  sx={{pt:'1rem'}}>
                <Grid item sx={{mb:"1rem"}}>
                    <Typography sx={{fontSize: "1.875rem", fontWeight: 600, color:'#333333'}}>{props.item.titre}</Typography>
                </Grid>
                <Grid item >
                   <Typography sx={{fontSize: "1rem", color:'#333333'}}>{props.item.contenu}</Typography>    
                </Grid>
            </Grid>
        </Grid>
        
        
    )
    
}
export default ItemWork;

