import React from 'react';
import './App.css';
import NavigationBar from './components/NavigationBar';
import {BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import Footer from './components/Footer';
import Accueil from './pages/Accueil';
import PageNotFound from './pages/PageNotFound';
import PhotoForm from './components/PhotosForm';
import MasonryImageList from './components/MansoryImageList';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline';
import ServicePage from './pages/ServicePage';
import WorkPage from './pages/WorkPage';
import ContactPage from './pages/ContactPage';




export default function App() {
  
  return (
    
 
  <Router>
    <div className="App">
      <NavigationBar/>
      <Routes>
        <Route path="*" element={<PageNotFound/>}/>
        <Route path="/" element={<Accueil/>}/>
        <Route path="/services" element={<ServicePage/>}/>
        <Route path="/realisations" element={<WorkPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/add" element={<PhotoForm photo={null} />}/>
        
      </Routes>
      <Footer/>
    </div>
  </Router>

  );
}






// <nav class="navbar navbar-expand-lg bg-light">
//         <div class="container-fluid">
//           <a class="navbar-brand" href="#">Sphertana</a>
//           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//             <span class="navbar-toggler-icon"></span>
//           </button>
//           <div class="collapse navbar-collapse" id="navbarSupportedContent">
//             <ul class="navbar-nav me-auto mb-2 mb-lg-0">
//               <li class="nav-item">
//                 <a class="nav-link active" aria-current="page" href="/">Accueil</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="#">Portrait</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="#">Paysage</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="#">Street art</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="/tableaux">Tableaux</a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>