import axios from 'axios';
export default class ArgentService {

    static getPhotos() {
      console.log("appel API getPhotos");
      return axios.get('http://localhost:3011/api/tableaux')
      .then(response =>  response.data)
      .catch(error =>  this.handleError(error));

    }
    static getTagsList(){
      console.log("appel API getTagsList");
      return axios.get('http://localhost:3011/api/SearchHelper')
      .then(response =>  response.data)
      .catch(error =>  this.handleError(error));

    }
    static handleError(error: any){
        
        console.error(error);
        throw error;
    }


}