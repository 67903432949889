import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools' 
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

console.log("aachienn");
const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      staleTime:Infinity
      }
  }
})



const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#333333',
    },
    // background:{
    //   default:'#fcfcfc',
    //   paper:'#fcfcfc'
    // },
    action:{
      hover:"#333333"
    },
    text:{
      primary:"#333333",
    }

  }
});
root.render(
<ThemeProvider theme={darkTheme}>
  <CssBaseline/>
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false}/>
    </QueryClientProvider>
    
  </React.StrictMode> 
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
