import React from 'react';
import { Box, Container, IconButton, Paper, Stack, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function Footer() {

  const sxIcon = { color:"#4F4F4F","&:hover": {
    backgroundColor: "transparent",
    cursor: "default",
    color:'#1B1B1B'
    }}

  return (
    <Paper sx={{
    width: '100%',
    bottom: 0,
    backgroundColor:""
    }} component="footer" square variant="outlined">
      <Container maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
            my: 2,
          }}
        >
          <Typography  alignSelf={'center'} color="#6b6b6b" >
            © 2023 Sphertana. Tout droit réservé
          </Typography>
          <Stack direction={"row"}>
          <IconButton sx={sxIcon} href='https://facebook.com/' aria-label="Facebook">
            <FacebookIcon/>
          </IconButton>
          <IconButton sx={sxIcon} href='https://twitter.com/' aria-label="Twitter">
            <TwitterIcon/>
          </IconButton>
          <IconButton sx={sxIcon} href='https://www.instagram.com/sphertana/' aria-label="Instagram">
            <InstagramIcon/>
          </IconButton>
          <IconButton sx={sxIcon} href='https://www.linkedin.com/' aria-label="add to shopping cart">
            <LinkedInIcon/>
          </IconButton>
          </Stack>
        </Box>
      </Container>
    </Paper>
  );
}