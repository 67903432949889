import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  styled,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { ItemSW } from "../models/ServiceData";

interface Props {
  item: ItemSW;
}

const ItemImage = styled("img")({
  objectFit: "contain",
  width: "100%",
});

function ItemService(props: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      sx={{
        borderRadius: "1rem",
        minHeight: "40vh",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "40%" } }}>
        <ItemImage src={props.item.ima} alt="Service" />
      </Box>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: { xs: "center", md: "flex-start" },
          width: { xs: "100%", md: "60%" },
        }}
      >
        <Box sx={{ mb: "1rem" }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 600, color: "#333333" }}
          >
            {props.item.titre}
          </Typography>
        </Box>
        <Box sx={{ mb: "1rem" }}>
          <Typography sx={{ fontSize: "1rem", color: "#000000" }}>
            {props.item.description}
          </Typography>
          <Typography sx={{ fontSize: "1rem", color: "#000000" }}>
            {props.item.formule}
          </Typography>
        </Box>
        <Grid
          container
          justifyContent="space-around"
          sx={{ mb: "1rem" }}
        >
          {props.item.tarifs.map((tarif, index) => (
            <Grid item xs={5} key={index}>
              <Typography
                sx={{ fontSize: "1rem", fontWeight: 600, color: "#000000" }}
              >
                {tarif.titre}
              </Typography>
              <Typography sx={{ fontSize: "1rem", color: "#000000" }}>
                {tarif.contenu}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Button variant="contained" onClick={handleOpen}>
          Réserver
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Réserver {props.item.titre}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veuillez entrer vos informations de réservation.
            </DialogContentText>
            {/* Form fields go here */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={handleClose}>Réserver</Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}

export default ItemService;
