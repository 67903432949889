import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

interface Props {
  item: string[];
}

export default function MasonryImageList(props: Props) {
  return (
    <Box sx={{ width: "auto", height: "auto", mx:12, my:7 }}>
      <ImageList variant="masonry" cols={4} gap={8}>
    
      {props.item.map((i) => (
        <ImageListItem >
          <img src={i} alt="" className="fluid" />
        </ImageListItem>
      ))}
    
      </ImageList>
    </Box>
  );
}
