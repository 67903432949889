import { Container } from '@mui/system';
import React, { useState } from 'react';
import HomeBanner from '../components/HomeBanner';
import Service from '../components/Service';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AnimatePresence, motion } from 'framer-motion';
import { Carousel } from '@mantine/carousel';

export default function Accueil() {

  const arrayRange = (start: number, stop: number, step: number) =>
        Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );

  const images = [
    require("../res/img1.jpg"),
    require("../res/img2.jpg"),
    require("../res/img2.jpg"),
    require("../res/img3.jpg"),
    require("../res/img4.jpg"),
    require("../res/img5.jpg"),
    require("../res/img6.jpg"),
    require("../res/PageNotFound.jpg"),
    require("../res/PageNotFound2.jpg"),
    require("../res/web.jpg"),
    
  ];


const button = {height:'100%',width:'100%', "&:hover": {
  backgroundColor: "transparent",
  cursor: "default",
  color:'#FFFFFF'
  }}

  return (
    <div>

      <HomeBanner/>
      <Service/>
      <Box display="flex" justifyContent="center" alignItems="center" mb="24px">
        <Typography variant='h2' color='prinary' fontFamily={'Poppins'} fontWeight={600} mx="auto">Regardez un peu !</Typography>
      </Box>
      <Carousel sx={{ maxWidth: "100%" }} height="100vh" loop>
        {
        arrayRange(0,images.length-1,1).map((i) => (
            <Carousel.Slide>
              <img src={images[i]} alt="lourd" className="contain" />
            </Carousel.Slide>
        ))
        }     
        </Carousel>
    </div>
        
  );
}