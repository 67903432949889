//@ts-nocheck

import { AppBar, Toolbar, IconButton, Box,Stack,Button,Typography, Drawer, List, ListItem, ListItemIcon, ListItemText} from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseRounded';
import MenuIcon from '@mui/icons-material/MenuRounded';
import React, {useState} from 'react'
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const sxButton = { color:'#ECECEC', fontFamily:"Roboto", fontWeight:'600',"&:hover": {
  backgroundColor: "transparent",
  cursor: "default",
  color:'#FFFFFF'
  }}

export default function Navbar() {
  const [open, setOpen] = useState(false);

  function handleMenuOpen() {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setOpen(false);
  };

  return (
    <AppBar position='fixed' elevation={0}  sx={{ backgroundColor:'#121212' }} >
      <Toolbar sx={{justifyContent:'space-between' , flexWrap: 'wrap' }}>
        <Box variant='h6' component={Link} to='/' underline='hover' sx={{ height:'70px' }}>
          <img src={require("../res/logo.png")} alt="" className="img-fluid" />
        </Box>
        <Stack direction='row' spacing={2} sx={{display: { xs: 'none', md: 'block' }}}>
          <Button variant='text' component={Link} to='/'  sx={sxButton}>Accueil</Button>
          <Button variant='text' component={Link} to='/services' sx={sxButton}>Service</Button>          
          <Button variant='text' component={Link} to='/realisations'  sx={sxButton}>Réalisation</Button>
          <Button variant='text' component={Link} to='/contact' sx={sxButton}>Contact</Button>
        </Stack>
        <IconButton onClick={handleMenuOpen} sx={{color:'#ffffff',display: { xs: 'block', md: 'none' }}}>
          <MenuIcon/>
        </IconButton>
        <Drawer PaperProps={{sx: {backgroundColor: "#121212", width:"100vw",justifyContent:'center'}}} anchor='right' open={open} onClose={handleMenuClose}>
          <IconButton sx={sxButton} onClick={handleMenuClose}>
            <CloseIcon />
          </IconButton>
          <List sx={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}} >
            <ListItem  component={Link} to='/'  sx={sxButton}  onClick={handleMenuClose}>
              <ListItemText sx={{textAlign:'center'}} primary='Accueil' />
            </ListItem>
            <ListItem  component={Link} to='/services'  sx={sxButton} onClick={handleMenuClose}>
              <ListItemText sx={{textAlign:'center'}} primary='Service' />
            </ListItem>
            <ListItem  component={Link} to='/realisations'  sx={sxButton} onClick={handleMenuClose}>
              <ListItemText sx={{textAlign:'center'}} primary='Réalisation' />
            </ListItem>
            <ListItem component={Link} to='/contact'  sx={sxButton}  onClick={handleMenuClose}>
              <ListItemText sx={{textAlign:'center'}} primary='Contact' />
            </ListItem>
          </List>
        </Drawer>
        
      </Toolbar>
      
    </AppBar>
  )
}