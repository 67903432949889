import { Box, Container, ThemeProvider, Typography, createTheme, Grid } from '@mui/material';
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { motion } from "framer-motion";

export default function Service() {
    const section = { px: '2rem', mb: '5rem'} 
    const titlesection = {fontSize: "1.5rem", fontWeight: 'bold', mb: 1, color: 'secondary'}
    const paragraphesection =  {fontSize: "1rem", color: "secondary"}

    return (
        <Box pt="15vh">
            <Container>    
                <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb="24px">
                    <Typography variant='h2' color='prinary' fontFamily={'Poppins'} fontWeight={600} mx="auto">Nos services</Typography>
                </Box>
                <Grid container justifyContent='center' >
                    
                    <Grid item xs={10} md={4} sx={section} >
                        <Link style={{all:'unset'}} to='/services#photo'>
                            <motion.div
                            className="box"
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 30 }}
                            >
                                <Box sx={{display:'flex', justifyContent:'center',  mb: "2.5rem",height: '10rem'}}>
                                    <img src={require("../res/photographer.png")} alt=""  />
                                </Box>
                                <Box>
                                    <Box sx={titlesection}>Photographie</Box>
                                    <Box sx={paragraphesection}>Chez Sphertana, nous créons des images uniques qui captivent votre audience et racontent une histoire. Nos photographes professionnels ont l'œil pour capturer des moments précieux, qu'il s'agisse d'événements, de portraits ou de produits. Nous nous engageons à fournir une qualité exceptionnelle et à travailler avec vous pour créer des images qui reflètent l'essence de votre marque. Avec Sphertana, vous pouvez vous attendre à des résultats qui dépassent vos attentes et à une expérience de photographie exceptionnelle.</Box>
                                </Box>
                            </motion.div>
                        </Link>
                        
                    </Grid>
                    
                    <Grid item xs={10} md={4} sx={section} >
                        <Link style={{all:'unset'}} to='/services#devweb'>    
                            <motion.div
                            className="box"
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 30 }}
                            >
                                <Box sx={{display:'flex', justifyContent:'center', mb: "2.5rem",height: '10rem'}}>
                                    <img src={require("../res/developpeur.png")} alt=""  />
                                </Box>
                                <Box>
                                    <Box sx={titlesection}>Web Development</Box>
                                    <Box sx={paragraphesection}>Faire son site chez Sphertana, c'est bénéficier d'un accompagnement personnalisé pour créer une plateforme en ligne à l'image de votre entreprise. Nous mettons notre expertise en photographie, développement web et marketing digital à votre service pour vous aider à atteindre vos objectifs de croissance et de visibilité. Avec nous, vous êtes sûr d'obtenir un site web professionnel, innovant et performant, qui vous permettra de marquer votre différence et de vous démarquer de vos concurrents.</Box>
                                </Box>
                            </motion.div>
                        </Link>
                    </Grid>
                    
                    
                    <Grid item xs={10} md={4} sx={section} >
                        <Link style={{all:'unset'}} to='/services#communitymanagement'>
                            <motion.div
                            className="box"
                            whileHover={{ scale: 1.1 }}
                            transition={{ type: "spring", stiffness: 400, damping: 30 }}
                            >
                                <Box sx={{display:'flex', justifyContent:'center', mb: "2.5rem",height: '10rem'}}>
                                    <img src={require("../res/team.png")} alt=""  />
                                </Box>
                                <Box>
                                    <Box sx={titlesection}>Marketing digital</Box>
                                    <Box sx={paragraphesection}>Faire votre marketing digital avec Sphertana, c'est la garantie d'une présence en ligne remarquée et d'une croissance rapide de votre entreprise. Nous sommes une agence dynamique et passionnée, qui allie stratégie, créativité et expertise technique pour vous aider à atteindre vos objectifs de marketing numérique. Nous sommes là pour vous accompagner à chaque étape du processus, en vous offrant des solutions sur mesure, innovantes et efficaces pour propulser votre entreprise en ligne. Alors, n'hésitez plus, faites nous confiance !</Box>
                                </Box>
                            </motion.div>
                        </Link>
                    </Grid>
                    
                </Grid>
            </Container>
        </Box>
        
    
  );
}