import React from 'react';
import { Box, Container, IconButton, Paper, Stack, Link, Typography, Grid, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { styled } from '@mui/system';
import { text } from 'stream/consumers';
import axios from 'axios';

export default function ContactPage() {

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      nom: data.get('firstName'),
      compagnie: data.get('company'),
      email: data.get('email'),
      telephone: data.get('phone'),
      message: data.get('message'),
    });
    
    console.log("appel API getPhotos");
    axios.post('http://localhost:3011/api/contact_us',data)
    .then(response =>  console.log(response.status)) 
  };

  const ContactLink = styled(Link)({
    backgroundColor: "transparent",
      cursor: "default",
      color:'#ffffff',
      '&:hover': {
        color:'#111111'
      },
  });

  const sxIcon = { color:"#ffffff","&:hover": {
    backgroundColor: "transparent",
    cursor: "default",
    color:'#ffffff'
    }}

    

    const sxTextField= {
      "& .MuiFormLabel-root": {
          color: '#333333'
      }
    }

  return (
    <Grid  className='no-banner' container direction='column' alignItems='center' justifyContent='center'  sx={{  backgroundColor:'#aaaaaa',minHeight:'100vh',width:'100%',px:'3rem'}}>
        <Box sx={{display:'flex', flexDirection:"column", alignItems:"center",minHeight:"20vh",py:6}} >
          <Typography fontWeight={600} fontSize={32}>
            Contactez nous
          </Typography>
          <Typography fontWeight={400} fontSize={24}>
            Vous avez une question ou une remarque? Laissez nous un message
          </Typography>
        </Box>
        <Grid  container justifyContent="center" rowSpacing={{ xs: 5,  md: 1 }} sx={{backgroundColor:'#ffffff',borderRadius:"10px",mb:5}}>
          <Grid item xs={12} md={5} container direction='column' alignItems='center' justifyContent='center' sx={{borderTopLeftRadius:"10px",borderBottomLeftRadius:"10px",backgroundColor:'#008E82'}} >
            <Box>
              <Typography fontWeight={'800'} fontFamily={"roboto"} fontSize={24} color={"#ffffff"}>
                Coordonnées
              </Typography>
            </Box>
            <Box>
              <Box>
                  <IconButton sx={sxIcon}>
                    <CallIcon/>
                  </IconButton>
                  <ContactLink href="tel:+33102030405" underline='none' gutterBottom={false} > +33 6 12 34 56 </ContactLink> 
              </Box>
              <Box>
                  <IconButton sx={sxIcon}>
                    <EmailIcon/>
                  </IconButton>
                  <ContactLink href="mailto:contact@sphertana.com" underline='none' > contact@sphertana.com </ContactLink> 
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7} direction='column' alignItems='center' 
            sx={{
              p:'5rem',borderRadius:2
            }}
          >   
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Nom"
                    autoFocus
                    sx={sxTextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    name="company"
                    fullWidth
                    id="company"
                    label="Compagnie"
                    autoFocus
                    sx={sxTextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    id="email"
                    label="Adresse mail"
                    name="email"
                    autoComplete="email"
                    sx={sxTextField}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    fullWidth
                    id="phone"
                    label="Téléphone"
                    name="phone"
                    autoComplete="phone"
                    sx={sxTextField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    required
                    fullWidth
                    name="message"
                    label="Message"
                    id="message"
                    multiline
                    rows={5}
                    sx={sxTextField}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,backgroundColor:'#333333',color:'#ffffff'}}
              >
                Envoyer
              </Button>
              
            </Box>
          </Grid>
        </Grid>
    </Grid>    
  );
}