import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ItemSC } from "../models/ServiceData";

interface Props {
    item: ItemSC;
}

function ItemServiceCom(props: Props) {
    
    return ( 
    <Grid item xs={12} sm={5}  sx={{p:'1rem', m:'1rem', borderRadius:2, boxShadow: 3}} >

        <Grid container direction='column' justifyContent='center' alignItems='center' sx={{pl:{sm:'1.5rem'}}}>

            <Grid item xs={1} container  alignItems='center' sx={{mb:"1rem"}}>
                <img src={props.item.ima} alt="" className="pl" />
                <Typography  sx={{fontSize: 18, fontWeight: 600, color:'#333333',pl:2}}>{props.item.titre}</Typography>
            </Grid>
            <Grid item xs={9} sx={{display:'flex', flexDirection: 'column',justifyContent:'center', alignItems:'center',height:'100%'}}>
                <Typography sx={{color:'#333333'}}>{props.item.contenu}</Typography>    
            </Grid>
        </Grid>

    </Grid>
        
    )
    
}
export default ItemServiceCom;

