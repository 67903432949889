import React, { useState } from 'react';
import { Box, Button, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ItemWork from '../components/ItemWork';
import MansoryList from '../components/MansoryImageList';
import { animateScroll } from 'react-scroll';

export interface ItemW {
    id:number;
    ima: string;
    titre: string;
    contenu: string;
}

const LandingBtn = styled(Button)({
  boxShadow: 'none',
  borderRadius: 28,
  '&:hover': {
    boxShadow: 'none',
  },
});

const Btn = styled(Button)({
  boxShadow: 'none',
  borderRadius: 28,
  backgroundColor: '#333333',
  '&:hover': {
    background: 'none',
    boxShadow: 'none',
    color:'#222222'

  },
  

  
});

export default function WorkPage() {
    const [page,setPage] = useState('photo')
    let element;
    const Image = require('../res/mountain.jpg')
    
    const array_work: ItemW[] = [
      {id:1,
      ima: require("../res/img1.jpg"),
      titre: "Boutique en ligne", 
      contenu: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat a massa non placerat."
      },
      {id:2,
      ima: require("../res/img2.jpg"),
      titre: "Site vitrine",
      contenu: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat a massa non placerat. (portolio, site d'agence,...)"
      },
      {id:3,
      ima: require("../res/PageNotFound.jpg"),
      titre: "Site de reservation",
      contenu: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat a massa non placerat."
      }
    ];
  const photos: string[] = [
      require("../res/img1.jpg"),require("../res/cms.jpg"),require("../res/PageNotFound2.jpg"),require("../res/img2.jpg"),
      require("../res/photo_banner.jpg"),require("../res/img3.jpg"),require("../res/webdesign.jpg"),require("../res/img4.jpg"),
      require("../res/_cms.jpg")
  ]

  const mariages: string[] = [
      require("../Photos/Mariage/DSCF0869.jpg"),
      require("../Photos/Mariage/DSCF0874.jpg"),
      require("../Photos/Mariage/DSCF0960.jpg"),
      require("../Photos/Mariage/DSCF1266.jpg"),
      require("../Photos/Mariage/DSCF1321.jpg"),
      require("../Photos/Mariage/DSCF1703.jpg"),
      require("../Photos/Mariage/DSCF1786.jpg"),
      require("../Photos/Mariage/DSCF1793.jpg"),
      require("../Photos/Mariage/DSCF1801.jpg"),
      require("../Photos/Mariage/DSCF09682.jpg"),
      require("../Photos/Mariage/DSCF10794.jpg"),
      require("../Photos/Mariage/DSCF10901.jpg"),
      require("../Photos/Mariage/DSCF13011.jpg"),
      require("../Photos/Mariage/DSCF13023.jpg"),
      require("../Photos/Mariage/DSCF17881.jpg"),
      require("../Photos/Mariage/DSCF27321.jpg"),
      require("../Photos/Mariage/DSCF1914.jpg"),
      require("../Photos/Mariage/DSCF25242(10).jpg"),
      require("../Photos/Mariage/DSCF25242(8).jpg"),
      require("../Photos/Mariage/DSCF25242(7).jpg"),
      require("../Photos/Mariage/DSCF25242(5).jpg"),
      require("../Photos/Mariage/DSCF25242(3).jpg"),
      require("../Photos/Mariage/DSCF25242(2).jpg")
  ]

  const immos: string[] = [
      require("../Photos/Immobilier/Cheminée0105.jpg"),
      require("../Photos/Immobilier/DSCF0269.jpg"),
      require("../Photos/Immobilier/DSCF4735.jpg"),
      require("../Photos/Immobilier/DSCF4826.jpg"),
      require("../Photos/Immobilier/DSCF0175.jpg"),
      require("../Photos/Immobilier/DSCF0340.jpg"),
      require("../Photos/Immobilier/DSCF46881.jpg"),
      require("../Photos/Immobilier/Jardin0248.jpg"),
      require("../Photos/Immobilier/DSCF0197.jpg"), 
      require("../Photos/Immobilier/DSCF4738.jpg"),
      require("../Photos/Immobilier/DSCF4826.jpg"),
      require("../Photos/Immobilier/DSCF7793.jpg"),
      require("../Photos/Immobilier/escalier0052.jpg"),
      require("../Photos/Immobilier/Escalier0074.jpg"),
      require("../Photos/Immobilier/Jardin0250.jpg"),
      require("../Photos/Immobilier/SousSol0002.jpg")
  ]

    const evenement: string[] = [
      require("../Photos/evenement/DSCF1608.jpg"),
      require("../Photos/evenement/DSCF1615.jpg"),
      require("../Photos/evenement/DSCF1662 .jpg"),
      require("../Photos/evenement/DSCF1671.jpg"),
      require("../Photos/evenement/DSCF1690.jpg"),
      require("../Photos/evenement/DSCF1705.jpg"),
      require("../Photos/evenement/DSCF1738.jpg"),
      require("../Photos/evenement/DSCF1698.jpg"),
      require("../Photos/evenement/DSCF1713.jpg"),
      require("../Photos/evenement/DSCF1739.jpg"),
      require("../Photos/evenement/DSCF3211 2.jpg"),
      require("../Photos/evenement/DSCF4056 1.jpg"),
      require("../Photos/evenement/DSCF3717 1.jpg"),
      require("../Photos/evenement/DSCF3492.jpg"),
      require("../Photos/evenement/DSCF3468 1.jpg"),
      require("../Photos/evenement/DSCF3642.jpg"),
      require("../Photos/evenement/DSCF4054 2.jpg"),
      require("../Photos/evenement/DSCF6700.jpg"),
      require("../Photos/evenement/DSCF8212.jpg"),
      require("../Photos/evenement/DSCF8193.jpg")
      
    ]

    const lorem: string[] = [
      require("../Photos/portraits/Chareek0125 1.jpg"),
      require("../Photos/portraits/DSCF7454 3.jpg"),
      require("../Photos/portraits/DSCF7574 1.jpg"),
      require("../Photos/portraits/DSCF7684.jpg"),
      require("../Photos/portraits/leila_60.jpg"),
      require("../Photos/portraits/Leila0151 1.jpg"),
      require("../Photos/portraits/Racky0189.jpg"),
      require("../Photos/portraits/Sirine0082.jpg"),
      require("../Photos/portraits/DSCF2305 1.jpg"),
      require("../Photos/portraits/DSCF2225 2.jpg"),
      require("../Photos/portraits/DSCF2219.jpg"),
      require("../Photos/portraits/DSCF7155.jpg"),
      require("../Photos/portraits/DSCF7358.jpg"),
      require("../Photos/portraits/DSCF2553 1.jpg")
    ]

    const portrait: string[] = [
      require("../Photos/portraits/Chareek0125 1.jpg"),
      require("../Photos/portraits/DSCF7454 3.jpg"),
      require("../Photos/portraits/DSCF7574 1.jpg"),
      require("../Photos/portraits/DSCF7684.jpg"),
      require("../Photos/portraits/leila_60.jpg"),
      require("../Photos/portraits/Leila0151 1.jpg"),
      require("../Photos/portraits/Racky0189.jpg"),
      require("../Photos/portraits/Sirine0082.jpg"),
      require("../Photos/portraits/DSCF2305 1.jpg"),
      require("../Photos/portraits/DSCF2225 2.jpg"),
      require("../Photos/portraits/DSCF2219.jpg"),
      require("../Photos/portraits/DSCF7155.jpg"),
      require("../Photos/portraits/DSCF7358.jpg"),
      require("../Photos/portraits/DSCF2553 1.jpg")
    ]
  

    switch (page) {
        case 'webdev':
          element = (<Box sx={{display:'flex',flexDirection:'column'}}>
            {array_work.map((i,index) => (
              <ItemWork key={index} item={i}/>
            ))}
            </Box>
          )
          break;
        case 'mariage':
          element = (
            <MansoryList item={mariages}/>
          );
          break;
        case 'portrait':
          element = (
            <MansoryList item={portrait}/>
          );
          break;
        case 'evenement':
          element = (
            <MansoryList item={evenement}/>
          );
          break;
        case 'lorem':
          element = (
            <MansoryList item={lorem}/>
          );
          break;
        case 'immo':
          element = (
            <MansoryList item={immos}/>
        );
          break;
        case 'photo':
            element = (
              <MansoryList item={photos}/>
          );
            break;
        default:
          return null;
    }

    function setPageName(p: string) {
        setPage(p)
        animateScroll.scrollTo(window.innerHeight)
    }

  return (
    <Box>
        <Grid container direction="row" justifyContent="center" alignItems="center" className='no-banner' 
      sx={{ backgroundImage: `url(${Image})` , backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center',
      minHeight: "100vh" }}>
            <Container sx={{display:"flex",flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Box mt='3rem'>
                    <Typography variant='h2' sx={{ textAlign:'center', fontFamily:"Poppins ", fontWeight:600, fontSize:90,color:'#ffffff'}}>
                    Nos réalisations
                    </Typography>
                    <Box sx={{textAlign:'center', my:4}}>
<Typography sx={{ fontFamily:"Poppins ", fontSize:18,color:'#ffffff'}}>
Jetez un oeil sur quelques-uns de nos travaux
</Typography>
</Box>
                    <Box sx={{display:"flex",flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <LandingBtn  variant="contained" onClick={() => {setPageName("photo") }} sx={{ backgroundColor:(page != "webdev" ? "":"transparent"), m:'1rem' }}>Photo</LandingBtn>
                        {/* <LandingBtn  variant="contained" onClick={() => {setPageName("webdev") }} sx={{ backgroundColor:(page == "webdev" ? " ":'transparent'), m:'1rem'}} >Web</LandingBtn> */}
                    </Box>
                </Box> 
            </Container>
        </Grid>
        <Box pt='100px'>
        {page != 'webdev'?
          <Box sx={{display:"flex",justifyContent:'center', alignItems:'center'}}>
          <Btn variant="contained" onClick={() => {setPageName("photo") }} sx={{ color:(page == 'photo' ? "#ffffff":"#949596"), backgroundColor:(page == "photo" ? " ":'transparent') }}>Photo</Btn>
          <Btn variant="contained" onClick={() => {setPageName("mariage") }} sx={{ color:(page == 'mariage' ? "#ffffff":"#949596"), backgroundColor:(page == "mariage" ? " ":'transparent') }} >Mariage</Btn>
          <Btn variant="contained" onClick={() => {setPageName("immo") }} sx={{ color:(page == 'immo' ? "#ffffff":"#949596"), backgroundColor:(page == "immo" ? " ":'transparent') }} >Immobilier</Btn>
          <Btn variant="contained" onClick={() => {setPageName("portrait") }} sx={{ color:(page == 'portrait' ? "#ffffff":"#949596"), backgroundColor:(page == "portrait" ? " ":'transparent') }} >Portrait</Btn>
          <Btn variant="contained" onClick={() => {setPageName("evenement") }} sx={{ color:(page == 'evenement' ? "#ffffff":"#949596"), backgroundColor:(page == "evenement" ? " ":'transparent') }} >Evenement</Btn>
          <Btn variant="contained" onClick={() => {setPageName("lorem") }} sx={{ color:(page == 'lorem' ? "#ffffff":"#949596"), backgroundColor:(page == "lorem" ? " ":'transparent') }} >Lorem</Btn>
        </Box>:null}
          {element}
        </Box>
    </Box>    
  );
}