import React, { useState } from "react";
import { Grid, Box, Typography, styled, IconButton, Divider } from "@mui/material";
import { ItemSP } from "../models/ServiceData";
import { Carousel } from "@mantine/carousel";

interface Props {
    item: ItemSP;
}

function ItemServicePhoto(props: Props) {
    let [ count_photo, setCountPhoto] = useState(0); 
    let [ tuple_photo, setTuplePhoto] = useState([0,count_photo]); 
    if (tuple_photo[1] !== count_photo){
        setTuplePhoto([tuple_photo[1],count_photo]);
    }
    let direction_photo = count_photo > tuple_photo[0] ? 'moins' : 'plus' ;

    let variants = {
        enter: (direction: string) => ({ x: direction === 'plus' ? '100vw' : '-100vw' }),
        center:{ x: 0 },
        exit:(direction: string) => ({ x: direction === 'plus' ? '-100vw' : '100vw' })
    }

    const button = {height:'100%',width:'100%', "&:hover": {
    backgroundColor: "transparent",
    cursor: "default",
    color:'#000000'
    }}

    const arrayRange = (start: number, stop: number, step: number) =>
        Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );
    return ( 
        <Grid container  direction={{xs:'column', sm: props.item.id % 2 == 0 ? "row" : "row-reverse"}}  justifyContent='flex-end'  sx={{ maxHeight:'100%' }}>
            <Grid item xs={12} md={6} >
            <Carousel sx={{ maxWidth: "100%" }} height="100vh" styles={{
        control: {
          '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
          },
        },
}}>
                {
                arrayRange(0,props.item.ima.length-1,1).map((i) => (
                    <Carousel.Slide>
                        <img src={props.item.ima[i]} className="fluid"/>
                    </Carousel.Slide>
                    
                ))
            }     
            </Carousel>

            </Grid>
            <Grid item xs={12}  md={6} container direction='column' alignItems='center' justifyContent='center'    sx={{px:'1rem',pt:'2rem',pb:'3rem',backgroundColor:'#ffffff'}}>
                <Grid item sx={{mb:"1rem"}}>
                    <Typography sx={{fontSize: "1.875rem", fontWeight: 600, color:'#000000'}}>{props.item.titre}</Typography>
                </Grid>
                <Grid item >
                   <Typography sx={{fontSize: "1rem", color:'#000000',pb:'1rem'}}>{props.item.description}</Typography>    
                   <Typography sx={{fontSize: "1rem", color:'#000000',pb:'1rem'}}>{props.item.formule}</Typography>    
                </Grid>
                <Grid item container justifyContent="space-around" >
                    <Grid item xs={5}>
                        <Typography sx={{fontSize: "1rem",fontWeight: 600, color:'#000000'}}>{props.item.tarifs[0].titre}</Typography>    
                        <Typography sx={{fontSize: "1rem", color:'#000000'}}>{props.item.tarifs[0].contenu}</Typography>    
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid item xs={5}>
                        <Typography sx={{fontSize: "1rem",fontWeight: 600, color:'#000000'}}>{props.item.tarifs[1].titre}</Typography>    
                        <Typography sx={{fontSize: "1rem", color:'#000000'}}>{props.item.tarifs[1].contenu}</Typography>    
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
    
}
export default ItemServicePhoto;

