import React from "react";


export interface ItemSW {
    id:number;
    ima: string;
    titre: string;
    description: string;
    formule: string;
    tarifs: tarif[];
}
export interface ItemSC {
    ima: string;
    titre: string;
    contenu: string;
}

export interface ItemSP {
    id:number;
    ima: string[];
    titre: string;
    description: string;
    formule: string;
    tarifs: tarif[];
}

export interface tarif {
    id: number;
    titre: string;
    contenu: string;
}

export const list_service_photo: ItemSP[] = [
    {id:1,
    ima: [require("../res/DSCF4047.jpg"),require("../res/DSCF6464.jpg"),require("../res/DSCF4337.jpg"),require("../res/DSCF8219.jpg")],
    titre: "Shooting d'événementiel",
    description: `La photographie d'événementiel est un service clé que nous offrons à nos clients. Que vous organisiez un mariage, une fête d'anniversaire, un événement d'entreprise ou un autre événement spécial, nous sommes là pour capturer chaque moment important et vous fournir des souvenirs durables et précieux. Nous proposons plusieurs forfaits pour nos services de photographie d'événementiel, en fonction de vos besoins et de votre budget.`,
    formule: `Notre forfait de base commence à partir de 400 euros pour des évènements tels que des séminaires ou des présentations de projet, et comprend jusqu'à 2 heures de couverture photographique, un nombre illimité de photos prises, et une galerie en ligne privée pour partager et télécharger les photos. 
    Pour ceux qui ont besoin de plus, nous proposons des forfaits sur mesure. Nous pouvons également offrir des options supplémentaires telles que des tirages, des albums photo personnalisés et des séances de portrait pour les invités. Nous sommes prêts à travailler avec vous pour assurer que votre événement soit parfaitement capturé.
    `,
    tarifs: [
        {id:0,
        titre:`Couverture photographique`,
        contenu: `• Couverture d’un événement, retouches sur 50 images choisies : à partir de 550 €. 
                  • Couverture partielle (par exemple, prise de vue pendant 2 heures), retouches sur 40 images choisies : à partir de 400 €` 
        },
        {id:1,
        titre:`Lancement de produit, séminaire, concept `,
        contenu: `• Couverture de l'événement tout au long de la journée (6 heures), retouches sur 100 images choisies : à partir de 900 € 
        • Couverture partielle (par exemple, prise de vue pendant 3 heures), retouches sur 40 images choisies : à partir 600 €`,
        }
    ]},
    {id:2,
    ima: [require("../res/DSCF0020.jpg"),require("../res/DSCF9976.jpg"),require("../res/DSCF9832.jpg"),require("../res/DSCF8804.jpg")],
    titre: "Shooting d'entreprise",
    description: `La photographie d'entreprise est un moyen essentiel pour renforcer l'image de marque d'une entreprise. Chez Sphertana, nous offrons des services de photographie d'entreprise de haute qualité pour aider votre entreprise à se démarquer. Nous sommes spécialisés dans la photographie de produits, de portraits d'employés, de locaux commerciaux et d'événements d'entreprise. Nous sommes également équipés pour produire des vidéos promotionnelles pour votre entreprise.`,
    formule: `Nos prix varient en fonction de la taille de l'entreprise, de la complexité de la mission et du temps de prise de vue. Pour une séance de photographie d'entreprise standard, nos prix commencent à partir de 400€. Nous proposons également des forfaits sur mesure pour répondre à tous vos besoins photographiques. Contactez-nous pour en savoir plus sur nos services de photographie d'entreprise.`,
    tarifs: [
        {id:0,
        titre:`Quelques tarifs`,
        contenu: `• shooting de produit ou mise en avant de prestation avec retouches sur 20 images minimum choisies : à partir de 300 € 
                  • Shooting d’employés à partir de 500 €
        ` 
        },
        {id:1,
        titre:``,
        contenu: `• évènement d’entreprise : Séance de 2h minimum avec retouches sur 45 images minimum choisies : à partir de 450 € 
                  • Séance de 3h multiservice avec retouches sur 60 images minimum choisies : a partir de 700 €
        ` 
        }
    ]},

    {id:5,
    ima: [require("../res/DSCF20912.jpg"),require("../res/Chareek0129.jpg"),require("../res/DSCF6630.jpg"),require("../res/DSCF69693.jpg")],
    titre: "Portrait",
    description: `La photographie de portrait est l'art de capturer l'essence et la personnalité d'une personne à travers des images intemporelles. Chez Sphertana, nous comprenons l'importance de ces moments spéciaux. 
    Nous travaillons avec nos clients pour créer un environnement confortable et détendu, où ils peuvent être eux-mêmes et se sentir à l'aise devant l'objectif.
    `,
    formule:`Nos séances photo de portrait commencent à partir de 180 euros pour une séance de 1 heure. 
    Contactez-nous pour plus d'informations sur nos tarifs et pour planifier votre séance photo de portrait personnalisée. Pour vous donner une idée sur les prix voici quelques situations : 
    `,
    tarifs: [
        {id:0,
        titre:`Portrait individuel`,
        contenu: `• Séance de 2h00 en studio avec retouches sur 30 images minimum choisies : à partir de 400 €
                  • Séance de 1h30 en extérieur avec retouches sur 20 images choisies : à partir de 250 €
        `
        },
        {id:1,
        titre:`Portrait de famille`,
        contenu: `• Séance de 3h en studio avec retouches sur 20 images choisies : à partir de 600 €
        • Séance de 2h en extérieur ou endroit particulier avec retouches sur 20 images choisies : à partir de 450 €
        `
        }
    ]},

    {id:4,
    ima: [require("../res/DSCF1914.jpg"),require("../res/DSCF2657.jpg"),require("../res/DSCF13021.jpg"),require("../res/DSCF1801.jpg")],
    titre: "Mariage",
    description: `
    La photographie de mariage est l’un des services le plus important pour nous que nous proposons. Nous comprenons l'importance de capturer les moments clés de votre journée spéciale et de créer des souvenirs inoubliables pour vous et votre famille. 
    Nous proposons des forfaits de photographie de mariage adaptés à tous les budgets, à partir de 800 euros pour une couverture de base. Le forfait de base comprend une séance photo d'engagement, la couverture de la cérémonie et des photos de groupe. `,
    formule:`Nous proposons également des forfaits plus complets, des séances photo de préparation, une couverture complète de la journée de mariage et une séance photo de couple en après-midi ou en soirée. Les forfaits complets commencent à partir de 1400 euros. 
    Contactez-nous pour discuter de vos besoins en matière de photographie de mariage et pour obtenir un devis personnalisé en fonction de vos besoins et de votre budget.
    `,
    tarifs: [
        {id:0,
        titre:``,
        contenu: `• Couverture de la préparation jusqu'à la fin de la soirée (8 heures), y compris des séances photo de pré-mariage, retouches sur 150 images choisies : à partir de 15OO euros.` 
        },
        {id:1,
        titre:``,
        contenu: `• Couverture partielle (par exemple, préparation à la cérémonie uniquement + mairie), retouches sur 70 images choisies : à partir de 700 €` 
        }
        // Quelques exemples : • Couverture de la préparation jusqu'à la fin de la soirée (8 heures) avec 2 photographes, retouches sur 100 images choisies, livraison des images sur support numérique haute résolution: a partir de 1490 euros.
        // • Couverture partielle (par exemple, préparation à la cérémonie uniquement) avec 1 photographe, retouches sur 30 images choisies, livraison des images sur support numérique haute résolution : entre 500 et 700 euros.
    ]},

    {id:5,
    ima: [require("../res/DSCF76253.jpg"),require("../res/img1.jpg"),require("../res/img1.jpg"),require("../res/img1.jpg")],
    titre: "Réalisation de vidéo",
    description: `Nous offrons également des services de réalisation de vidéos de haute qualité pour différents besoins, tels que les vidéos promotionnelles, les vidéos de marque, les vidéos d'entreprise, les vidéos d'événements, les vidéos de mariage et les vidéos de musique. 
    Nous avons une équipe talentueuse de vidéastes qui travailleront avec vous pour créer une vidéo qui raconte une histoire captivante et émouvante.`,
    formule: `Nos tarifs varient en fonction de la complexité du projet et de la durée de la vidéo. Nos prix de base pour une vidéo commencent à partir de 500 euros. Cela comprend la planification, le tournage, le montage, la musique de fond et l'ajout de textes et de graphiques.
    Nous offrons également des services supplémentaires tels que la prise de vue en drone, l'enregistrement de son professionnel et l'animation graphique. Ces services peuvent être ajoutés à votre forfait vidéo de base pour un coût supplémentaire. Contactez-nous pour discuter de vos besoins en matière de vidéo et recevoir un devis personnalisé.`,
    tarifs: [
        {id:0,
        titre:``,
        contenu: `` 
        },
        {id:1,
        titre:``,
        contenu: `` 
        }
        
    ]},

  ];

export const list_service_dev: ItemSW[] = [

    {id:0,
    ima: require("../res/_webdesign.jpg"),
    titre: "Site vitrine",
    description: `Notre service de création de site vitrine est conçu pour aider les entreprises à se présenter efficacement en ligne. Nous créons des sites web élégants, modernes et personnalisés qui reflètent l'identité de votre entreprise et mettent en avant vos produits et services. Nous 
    travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins et objectifs, et pour concevoir un site web qui répond à ces critères.
    Notre offre de site vitrine inclut la conception graphique, le développement technique, l'intégration de contenu, l'optimisation pour les moteurs de recherche (SEO), ainsi que la formation à l'utilisation du CMS (Content Management System) pour que vous puissiez gérer votre site de manière autonome.`,
    formule:`Les prix pour un site vitrine peuvent varier en fonction de la complexité du projet, du nombre de pages et de fonctionnalités souhaitées. En général, nos tarifs pour un site vitrine de base commencent à partir de 700€ et peuvent aller jusqu'à 2000€ ou plus pour des projets plus élaborés.
    Nous sommes convaincus que notre expertise en web design et notre expérience en création de sites vitrine seront à la hauteur de vos attentes et vous permettront de vous démarquer sur la toile. Contactez-nous dès maintenant pour discuter de votre projet et obtenir un devis personnalisé.`,
    tarifs: [
        {id:0,
        titre:``,
        contenu: `` 
        },
        {id:1,
        titre:``,
        contenu: `` 
        }
        
    ]},
    

    {id:1,
    ima: require("../res/_cms.jpg"),
    titre: "site de reservation",
    description: `La création d'un site de réservation est un excellent moyen pour les entreprises qui proposent des services de réservation (comme des hôtels, des restaurants, des locations de vacances, des activités touristiques, etc.) de gérer leurs réservations de manière plus efficace. Chez Sphertana, nous proposons des services de création de sites de réservation personnalisés pour répondre aux besoins de chaque client.
    Nous pouvons créer un site de réservation complet avec des fonctionnalités telles que la gestion des réservations, l'intégration de systèmes de paiement, la messagerie, la gestion des avis et bien plus encore. Nous établissons une liaison directe avec nos clients pour comprendre leurs besoins et concevoir un site web qui reflète leur marque et répond à leurs exigences spécifiques.`,
    formule:`Nos prix pour la création de sites de réservation varient en fonction de la complexité du site, des fonctionnalités requises et du temps de développement nécessaire. En général, nos prix pour la création de sites de réservation commencent à partir de 1 300 €, mais peuvent varier en fonction des besoins spécifiques du client.
    Nous sommes fiers de fournir des services de qualité à des prix abordables, tout en offrant un support technique continu pour garantir que le site de réservation fonctionne toujours de manière optimale. Contactez-nous dès maintenant pour en savoir plus sur nos services de création de sites de réservation.`,
    tarifs: [
        {id:0,
        titre:``,
        contenu: `` 
        },
        {id:1,
        titre:``,
        contenu: `` 
        }
        
    ]},
    
    {id:2,
    ima: require("../res/_cms.jpg"),
    titre: "site de E-commerce",
    description: `Notre service de création de site e-commerce est conçu pour aider les entrepreneurs et les entreprises à lancer leur boutique en ligne et à augmenter leurs ventes en ligne. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins, leurs objectifs et leur public cible, afin de créer une boutique en ligne qui correspond à leur marque et qui répond aux attentes de leur public.
    Nous proposons une gamme complète de fonctionnalités, y compris des options de paiement en ligne sécurisées, une gestion simplifiée des stocks et des commandes, une intégration facile aux réseaux sociaux et une conception responsive adaptée aux mobiles. Nous offrons également des options de personnalisation pour répondre aux besoins spécifiques de nos clients.`,
    formule:`Nos prix varient en fonction de la complexité de chaque projet. Nous proposons une formule de base pour les petites entreprises, qui comprend la création de la boutique en ligne, la mise en place d'un système de paiement en ligne sécurisé, l'intégration aux réseaux sociaux et la gestion des stocks et des commandes. Cette formule commence à partir de 1 500€.
    Pour les entreprises ayant des besoins plus importants, nous proposons des options de personnalisation supplémentaires, comme la création de fiches produits plus détaillées, l'intégration de systèmes de marketing avancés et la mise en place de fonctionnalités de personnalisation avancées. Ces projets sont généralement personnalisés pour répondre aux besoins de chaque entreprise, et leurs prix varient en conséquence.`,
    tarifs: [
        {id:0,
        titre:``,
        contenu: `` 
        },
        {id:1,
        titre:``,
        contenu: `` 
        }
        

    ]},
  ];

export const list_service_com: ItemSC[] = [
    {ima: require("../res/icons8-megaphone-64.png"),
    titre: "communication digitale",
    contenu: `Formule de base : Cette formule comprend la création et la gestion d'une page professionnelle sur les réseaux sociaux, ainsi que la publication régulière de contenu. 
    Elle peut également inclure la gestion de campagnes publicitaires simples sur les réseaux sociaux, comme Facebook Ads ou Instagram Ads.`
    },
    {ima: require("../res/icons8-seo-64.png"),
    titre: "Optimisation pour les moteurs de recherche",
    contenu: `Formule de référencement : Cette formule comprend l'optimisation du référencement naturel (SEO) de votre site internet, la création de backlinks et l'amélioration de la structure de votre site. Elle peut également inclure la création et la publication de contenu optimisé pour les moteurs de recherche. 
    `
    },
    {ima: require("../res/icons8-content-management-64.png"),
    titre: "Marketing de contenu",
    contenu: `Formule de marketing de contenu : Cette formule comprend la création régulière de contenu de qualité (articles de blog, vidéos, infographies, etc.) pour votre site internet et vos réseaux sociaux. 
    Elle peut également inclure la création et la gestion d'un blog pour votre entreprise.`
    },
    {ima: require("../res/icons8-target-audience-64.png"),
    titre: "Marketing d'influence",
    contenu: `Formule de publicité en ligne : Cette formule comprend la gestion de campagnes publicitaires plus avancées sur les moteurs de recherche (Google Ads) et les réseaux sociaux. 
    Elle peut également inclure la création de bannières publicitaires et la mise en place d'un suivi de conversion pour mesurer les résultats de vos campagnes.`
    }
  ];