import React from 'react';
import { Link } from 'react-router-dom';
// TODO changer message mauvaise page et photo
  
const PageNotFound = () => {
  
  return (
    <div className="container no-banner">
      <div className="row justify-content-center">
        <div className="col-12 col-md-5 text-center">
          <img src={require("../res/PageNotFound.jpg")} alt="Page non trouvée" className="img-fluid"/>
        </div>
        <div className="text-center" >
          <h1>Oué ça vient d'où</h1> 
          <Link to="/">
            Viens là
          </Link> 
        </div>
      </div>
    </div>

  );
}
  
export default PageNotFound;