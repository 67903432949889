import React from 'react';
import { Box, Container, Grid, Paper, Typography, Button } from '@mui/material';
import ItemService from '../components/ItemService';
import ItemServicePhoto from '../components/ItemServicePhoto';
import ItemServiceCom from '../components/ItemServiceCom';
import { list_service_com, list_service_dev, list_service_photo } from '../models/ServiceData';
import {  scroller } from 'react-scroll';
import { Carousel } from '@mantine/carousel';


export default function ServicePage() {
    
    const Image = require('../res/mountain2.jpg')

    const arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
    );

    const IPhoto = require('../res/photo_banner.jpg');
    const IDev = require('../res/dev_banner.jpg');
    const ICom = require('../res/com_banner.jpg');

    function scrollo(to:string): void {
        scroller.scrollTo(to, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        })
    }

    let heightWebItem = document.querySelector('.item')?.clientHeight;
    console.log(heightWebItem)
 
    return (
    <Box sx={{overflowX:'hidden'}}>
        <Grid container direction="row" justifyContent="center" alignItems="center" className='no-banner' 
        sx={{ backgroundImage: `url(${Image})` , backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center', minHeight: "100vh" }}>
            <Container sx={{display:"flex",flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Box mt='3rem'>
                    <Typography variant='h2' sx={{ textAlign:'center', fontFamily:"Poppins ", fontWeight:600, fontSize:90,color:'#ffffff'}}>
                    Nos services
                    </Typography>
                    <Box sx={{ backgroundColor:'#222222ad', borderRadius:4, p:2, m:{xs:2}  }}>
                        <Typography textAlign='center' sx={{fontFamily:"Poppins ", fontSize:{xs:12,md:16},color:'#ffffff',zIndex:10}}>
                        Chez Sphertana, nous sommes passionnés par la création d'images visuellement époustouflantes et la mise en avant de vos projets. Que vous soyez une entreprise, un entrepreneur ou un particulier, nous travaillerons avec vous pour créer des images et des supports de communication qui vous distinguent de la concurrence. Avec notre expertise en photographie, design et marketing digital, nous pouvons aider votre entreprise à réussir en créant une image de marque cohérente et en communiquant efficacement avec votre public cible. Que vous ayez besoin de portraits professionnels pour votre entreprise, de photos de votre prochain événement, d'un site web professionnel ou d'une stratégie de marketing digital pour attirer de nouveaux clients, nous sommes là pour vous accompagner à chaque étape du processus. Avec notre passion pour la créativité et notre engagement envers l'excellence, nous sommes déterminés à vous aider à atteindre vos objectifs.
                        </Typography>
                    </Box>
                    <Box sx={{display:"flex",flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Button  variant="contained" onClick={() => scrollo('photo')} sx={{ m:'1rem' }}>Photographie</Button>
                        <Button  variant="contained" onClick={() => scrollo('devweb')} sx={{ m:'1rem' }}>Developpement Web</Button>
                        <Button  variant="contained" onClick={() => scrollo('communitymanagement')} sx={{ m:'1rem' }}>Marketing digital</Button>

                    </Box>
                </Box>
                
            </Container>
        </Grid>
        {/* Photo */}
        <Box id="photo">
            <Paper sx={{ backgroundImage: `url(${IPhoto})` , backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat', minHeight:'100vh',  
            display:'flex', justifyContent:'center',alignItems:'center'}}>
                <Typography color={'#d6d5d3'} fontFamily={'Poppins'} fontWeight={600} sx={{fontSize:{ xs:40,sm:60}}}>
                Photographie
                </Typography>
            </Paper>
            <Grid py='1rem'>
                {list_service_photo.map((i,index) => (
                    <ItemServicePhoto key={index} item={i}/>
                ))}
            </Grid>
        </Box>

        {/* Developpement web */}
        <Box id="devweb">
            <Paper sx={{ backgroundImage: `url(${IDev})` , backgroundSize:'cover', backgroundRepeat:'no-repeat', minHeight:'100vh',  display:'flex', justifyContent:'center',alignItems:'center'}}>
                <Typography color={'#d6d5d3'} fontFamily={'Poppins'} fontWeight={600} sx={{fontSize:{ xs:40,sm:60}}}>
                Developpement Web
                </Typography>
            </Paper>
            <Grid container direction= {{xs: "column",sm:"row"}} justifyContent='center' alignItems='center' sx={{backgroundColor:'#333333', p:10,mb:5}}>
                <Box sx={{mb:"1rem"}}>
                    <Typography sx={{fontSize: 18, fontWeight: 600, color:'#ffffff'}}>Création de site web</Typography>
                </Box>
                <Box >
                    <Typography sx={{ color: '#cccccc' }}>
                    Sphertana propose des services de création de site web pour les entreprises et les particuliers. Nous sommes convaincus que la présence en ligne est cruciale pour toute entreprise moderne et notre équipe est là pour aider nos clients à avoir une présence en ligne forte et professionnelle.
                    <br/><br/>
                    Nous concevons des sites web sur mesure, faits pour répondre aux besoins spécifiques de chaque client. Nos sites web sont modernes, ergonomiques, faciles à utiliser et adaptés à tous les appareils en responsive (ordinateurs, smartphones, tablettes). Nous pouvons également implémenter un système CMS pour que vous puissiez gérer vous-même facilement tout en restant indépendant, ce pour quoi vous nous contactez le plus souvent.
                    <br/><br/>
                    Nous nous engageons à fournir des sites web de haute qualité à des prix compétitifs. Nos prix de référence pour la création de site web varient en fonction de la complexité du projet.
                    Pour un site web de base, notre tarif debute a partir de 600€, mais pour des sites plus complexes (comme des sites e-commerce), les prix peuvent aller jusqu'à 2900€. Cependant, nous offrons une consultation gratuite pour discuter des besoins de nos clients et établir un devis personnalisé pour leur projet.
                    </Typography>   
                </Box>
            </Grid>
            <Carousel slideSize={"100%"} height="100%" slideGap="md" loop withIndicators>
            {
                arrayRange(0,list_service_dev.length-1,1).map((i) => (
                    <Carousel.Slide>
                        <ItemService item={list_service_dev[i] }/>
                    </Carousel.Slide>
                    
                ))
            }
            </Carousel>
        </Box>

        {/* Marketing digital */}
        <Box id="communitymanagement" >
            <Paper sx={{ backgroundImage: `url(${ICom})` , backgroundSize:'cover', backgroundRepeat:'no-repeat', minHeight:'100vh',  display:'flex', justifyContent:'center',alignItems:'center'}}>
                <Typography color={'#d6d5d3'} fontFamily={'Poppins'} fontWeight={600} sx={{fontSize:{ xs:40,sm:60}}}>
                Marketing digital
                </Typography>
            </Paper>
            <Grid container direction= {{xs: "column",sm:"row"}} justifyContent='center' alignItems='center' sx={{backgroundColor:'#333333', p:10,mb:5}}>
                <Box sx={{mb:"1rem"}}>
                    <Typography sx={{fontSize: 18, fontWeight: 600, color:'#ffffff'}}>Montez le rythme </Typography>
                </Box>
                <Box >
                    <Typography sx={{ color: '#cccccc' }}>
                    Le marketing digital est une méthode incontournable pour toute entreprise souhaitant se développer et se démarquer sur le marché actuel. Il englobe différentes techniques telles que la publicité en ligne, les réseaux sociaux, l'emailing, le référencement naturel, le marketing d'influence et bien d'autres encore.
                    <br/><br/>
                    En choisissant nos services de marketing digital, vous bénéficiez d'une expertise et d'une expérience dans la mise en place de stratégies digitales efficaces pour votre entreprise. Nous sommes en mesure de vous accompagner dans l'élaboration d'une stratégie sur-mesure pour répondre à vos objectifs de croissance et d'augmentation de votre chiffre d'affaires.
                    <br/><br/>
                    Nous utilisons des outils performants pour mesurer les résultats de nos campagnes et vous fournir des rapports détaillés et réguliers sur l'évolution de votre visibilité en ligne. Nous sommes également à l'écoute de vos besoins et de vos remarques pour adapter notre stratégie en fonction de l'évolution de votre entreprise et de votre marché.
                    <br/><br/>
                    En choisissant nos services de marketing digital, vous vous assurez une visibilité accrue sur le web et sur les réseaux sociaux, une audience ciblée et engagée, ainsi qu'une augmentation de votre notoriété et de vos ventes. Nos devis et conseils sont gratuits, contactez nous on se fera un plaisir de vous repondre !
                            
                    </Typography>   
                </Box>
            </Grid>
            <Grid container direction='row' justifyContent='center' >
            {list_service_com.map((i,index) => (
                    <ItemServiceCom key={index} item={i}/>
                ))}
            </Grid>
        
        </Box>
    </Box>
  );
}