import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import './PhotosForm.css';
import React, { useEffect, useState } from 'react';
import { useNavigate ,Link, createPath} from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import Photo from '../models/photo';
import ArgentService from '../services/argent-service';
import { useQuery } from 'react-query';
// Definir des regles de validation pour src,titre et description



const PhotoForm   = ({ photo } :{photo:Photo|null}) => {
  const tagLabel = document.createElement("label");
        
  tagLabel.htmlFor="tags"
  tagLabel.textContent = 'Tags';      
  useEffect(() => {
        
        const input: HTMLInputElement = document.querySelector("#spanTags > ul > li > input") as HTMLInputElement;
        const  tagLabel_parent : HTMLInputElement  =  document.querySelector("#spanTags > ul > li") as HTMLInputElement;

        input.id="tags" 
        input.title="Entrez les tags de la photo"
        tagLabel_parent.appendChild(tagLabel) 
        tagLabel_parent.classList.add("form-floating")

  }, []);
  
  

  const queryKey = ["SearchHelper"]
  const {isFetching, data:SearchHelper,isError} = useQuery(queryKey,ArgentService.getTagsList);
  let tagsList :Array<string> = [];
  const [suggestions, setSuggestions] = useState<any[] | undefined>();
  const [tagSelectionnes, setTagSelectionnes] = useState <Array<string> > ([]);
  if (!isFetching){
    tagsList = SearchHelper[0].tagsList;

  }

  const affinerSuggestions = (event : { query: string })=>{
    // console.log("event",event)
    setTimeout(() => {
      let _suggestions : Array<string>;
      if (!event.query.trim().length) {
        _suggestions = [...tagsList];
      }
      else {
        _suggestions = tagsList.filter((tag) => {
              return tag.toLowerCase().startsWith(event.query.toLowerCase());
          });
      }
      const valeurs = tagSelectionnes.map((tag:string) =>tag.toLowerCase())
      console.log("valeurs,tagSelectionnes",valeurs,tagSelectionnes)
      _suggestions = _suggestions.filter(suggestion => !valeurs.includes( suggestion.toLowerCase()))
      setSuggestions(_suggestions);
  }, 250);
  }

  
  

  // const navigate = useNavigate();
  const[was_validated,setWas_validated] =useState("no-banner");
  const[classUrl,setClassUrl] =useState("form-control");

  const handleOnchangeUrl = (e :React.ChangeEvent<HTMLInputElement>)=>{
    console.log(e)
    const input = e.target;
    input.setCustomValidity("");
    const feedback = document.querySelector("#add-edit-form > div > div > div.col-12.col-md-6 > div:nth-child(1) > div.invalid-feedback")
    if (feedback)
      feedback.textContent = "L'URL source est requise.";

    else
      throw new Error()
    setClassUrl("form-control");
    
    
    
  }
  const handleClickVerifier = async ()=>{
    const elem : HTMLInputElement|null =  document.getElementById("urlSource") as HTMLInputElement;
    console.log(elem);
    console.log("Dans handleClickVerifier");

    if (elem.checkValidity()){
      console.log("checkValidity true");
      setClassUrl("form-control is-valid");
      const  img = document.getElementById("visuelle") as HTMLInputElement;
      
      return new Promise <void> ((resolve, reject) => {
        img.src = elem.value;
        console.log("apres changement de src");
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
    }
    else setClassUrl("form-control is-invalid");
  }
  
  const handleErrorImg = (e:React.ChangeEvent<HTMLImageElement>) =>{
    console.log("Dans handleErrorImg");
    e.target.src = require("../res/Form.gif")
    const feedback = document.querySelector("#add-edit-form > div > div > div.col-12.col-md-6 > div:nth-child(1) > div.invalid-feedback") as HTMLInputElement;
    feedback.textContent = "L'URL entrée n'a pas permis de chargée d'image."
    const input = document.querySelector("#urlSource") as HTMLInputElement;
    input.setCustomValidity("L'URL entrée n'a pas permis de chargée d'image.");
    document.querySelector("#add-edit-form > div > div > div.col-12.col-md-6 > div:nth-child(1) > div.invalid-feedback")
    
    console.log(input.validity.valid)
    setClassUrl("form-control is-invalid");
  }
  const handleKeyPressAutocomplete = (e :any)=>{// TODO changer ANY
    console.log(e)
    console.log("handle tagSelectionnes",tagSelectionnes)
    if (e.key === 'Enter'){
      console.log("Enter")
      e.preventDefault()
      const input =document.getElementById("tags") as HTMLInputElement;
      const value :string = input.value.trim()
      input.value=""
      if (!value.length) return
      const value_low = value.toLowerCase();


      if (!tagsList.some(tag=>tag.toLowerCase() === value_low))
        tagsList.push(value);

      if (!tagSelectionnes.some((tag:string) => tag.toLowerCase() === value_low))
        setTagSelectionnes([...tagSelectionnes,value]);

      
      
    }
    if (e.key==="Backspace"){
      const tag =  document.getElementById("tags") as HTMLInputElement;
      if (tag.value){
        if (tagSelectionnes.length===1)
          setTagSelectionnes([])
      }
    }
  }


  const handleSubmit =(e:React.FormEvent<HTMLFormElement>) => {
    console.log("danse le handle submit")
    e.preventDefault();
    handleClickVerifier()
    .then(()=>{
    console.log("dans le then")
    if (!photo) photo = new Photo();
    // TODO met
    // photo.src = document.getElementById("urlSource").value;
    // photo.titre = document.getElementById("titre").value;
    // photo.description = document.getElementById("description").value;
    // photo.prix = document.getElementById("prix").value;
    photo.tag = tagSelectionnes;
    console.log(photo)
      
      
      if (photo.id!==0)
      {}
    // modifi photo
    //   ArgentService.updatePhoto(photo).then( () => history.push(`/pokemons/${pokemon.id}`));
      else{
        // Ajout photo
        // ArgentService.addPhoto(photo).then( (reponse) => history.push(`/photo/${reponse.id}`));
      }


    })
    .catch(()=> console.log("dans le catch"))
    
    

      

  }


const deletePhoto = ()=>{ 
  // TODO: this
  // PokemonService.deletePhoto(photo).then(()=>history.push('/TODO')) ;
};
  return (

      <form className={was_validated} onSubmit={handleSubmit} autoComplete="off" id="add-edit-form" >
            
        {/* <style>.bootstrap-iso .formden_header h2, .bootstrap-iso .formden_header p, .bootstrap-iso form{font-family: Arial, Helvetica, sans-serif; color: #000000}.bootstrap-iso form button, .bootstrap-iso form button:hover{color: white !important;} </style> */}
      
        <style>{"\
        .asteriskField{\
          color:red;\
        }\
      "}</style>

      <div className="container px-5 my-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 ">
          <div className="form-floating mb-3 text-center">
            <img onError={handleErrorImg} id="visuelle" style={{maxWidth: "100%"}} src={require("../res/Form.gif")} alt="Visuelle" /> 
            <label className="text-center" htmlFor="visuelle">Visuelle</label>
          </div>
          </div>
          <div className="col-12 col-md-6">

          

          

          
          <div className="form-floating mb-4">
              <input onKeyPress={e=> e.key=== "Enter"?handleClickVerifier().then(()=>{}).catch(()=>{}):null } onChange={handleOnchangeUrl}  title="Entrez le lien de la photo" className={classUrl} id="urlSource" type="text" placeholder="URL Source" required />
              <div style={{textAlign:"right"}}>
              <button  style={{position:"absolute",zIndex:"100" ,top:"0" ,marginLeft:"-96px"}} className=" btn btn-outline-secondary" onClick={()=>handleClickVerifier().then(()=>{}).catch(()=>{})} type="button">Vérifier</button>
              </div>
              <label htmlFor="urlSource"><span className="asteriskField">*</span>URL Source</label>
              
              <div className="invalid-feedback" >L'URL source est requise.</div>
          </div>
          <div className="form-floating mb-4">
              <input title="Entrez le titre de la photo" className="form-control"  id="titre" type="text" placeholder="Titre" required />
              <label  htmlFor="titre"><span className="asteriskField">*</span>Titre</label>
              
              <div className="text-danger invalid-feedback"  >Le titre est requis.</div>
          </div>
          <div className="form-floating mb-4">
              <input title="Entrez la description de la photo" className="form-control" id="description" type="text" placeholder="Description" required />
              <label htmlFor="description"><span className="asteriskField">*</span>Description</label>
              <div className="invalid-feedback" >La description est requise.</div>
          </div>
          <div className="form-floating mb-4">
              <input title="Entrez le prix de la photo" min="0" className="form-control" id="prix" type="number" placeholder="Prix" required />
              <label htmlFor="prix"><span className="asteriskField">*</span>Prix</label>
              <div className="invalid-feedback" >Un prix supérieur ou égal à 0 est requis.</div>
          </div>
          <div className="form-floating mb-3">
              <AutoComplete  onKeyDown={handleKeyPressAutocomplete} style={{width:"100%"}} id="spanTags" placeholder="Tags" inputClassName="form-control" className="form-floating" onChange={(e) => setTagSelectionnes(e.value)} value={tagSelectionnes} suggestions={suggestions} completeMethod={affinerSuggestions} multiple dropdown />
          </div>
          <div className="mb-3">
              <label className="form-label d-block"></label>
              <div className="form-check form-check-inline">
                  <input  title="Dîtes la vérité" className="form-check-input" id="tonyEstPlusFortQueMoi" type="checkbox" name="" required />
                  <label className="form-check-label" htmlFor="tonyEstPlusFortQueMoi">Tony est plus fort que moi</label>
              </div>
              <div className="invalid-feedback" >La vérité est requise.</div>
          </div>
          <div className="d-none" id="submitSuccessMessage">
              <div className="text-center mb-3">
                  <div className="fw-bolder">Soumission réussie</div>
              </div>
          </div>
          <div className="d-none" id="submitErrorMessage">
              <div className="text-center text-danger mb-3">Erreur</div>
          </div>
          <div className="d-grid">
              <button  onClick={()=>setWas_validated('no-banner was-validated')} style={{backgroundColor: "#212529"}}  className="btn btn-primary btn-lg " id="submitButton" type="submit">Ajouter la photo à la base de données</button>
          </div>
          </div>
          </div>
      </div>
      </form>
      

  
  );
};

export default PhotoForm;